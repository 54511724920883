
const ourStoryPage = document.querySelector('.owners-hub-page');

if (ourStoryPage !== null) {
  const scrollAnchors = ourStoryPage.querySelectorAll('.owners-hub-page__nav-items .nav-link');

  if (scrollAnchors.length) {

    scrollAnchors.forEach(item => {

      if (item.hash != '') {
        item.onclick = (event) => {
          event.preventDefault();
  
          const target = ourStoryPage.querySelector(event.target.hash);
  
          $('html, body').animate({
            scrollTop: target.offsetTop
          }, 1000);
        };
      }
      
    });
  }
}
