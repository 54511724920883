(($) => {
  
  const TRIGGER_SELECTOR = '.footer-navigation__column-header-expand-button';
  const EXPANDED_CLASS = 'footer-navigation__column-header-expand-button--expanded';

  const $footerNav = $('.footer-navigation');


  if (!$footerNav.length) {
    return;
  }

  const $columns = $footerNav.find('.footer-navigation__column');

  $columns.each((i, column) => {
    const $column = $(column);
    const $trigger = $column.find(TRIGGER_SELECTOR);
    const $subMenu = $column.find('.footer-navigation__menu');
    
    $subMenu.on('shown.bs.collapse', () => {
      $trigger.addClass(EXPANDED_CLASS);
    })
    .on('hidden.bs.collapse', () => {
      $trigger.removeClass(EXPANDED_CLASS);
    });

  });
})(jQuery);