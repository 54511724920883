import React from 'react';
import ReactDOM from 'react-dom';

import HeaderSearch from './HeaderSearch.jsx';

const container = document.getElementById('searchPanel');

if (container) {
  
  try {    
    const props = JSON.parse(container.dataset.initialData);

    props.innerHTML  = container.innerHTML; 
    
    ReactDOM.render(<HeaderSearch {...props}/>, container);

  } catch {
    console.warn('HeaderSearch: failed to initialize component');
  }

}