import React, {useState, useEffect} from 'react';
import Modal from 'react-bootstrap/Modal';

import Button from '../common/Button.jsx';

const TemplateNotificationModal = (props) => {
  
  const [isOpen, setOpen] = useState(false);
  const [messages, setMessages] = useState(['']);

  useEffect(() => {
    window.TemplateNotificationModal = {
      showMessages: openModal,
    };
  }, []);

  const hideModal = () => {
    setOpen(false);
    setMessages(['']);
  };

  const openModal = (messages) => {
    setMessages(messages);
    setOpen(true);
  };

  return (
    <Modal 
      show={isOpen}
      onHide={hideModal}
      className="notification-modal"
      centered>
        
        <div className="modal-content">
            <div className="modal-body">
                <button onClick={hideModal} className="notification-modal__x-button"><i className="fal fa-times"></i></button>
                {messages.map((m, i) => <p key={i} className="notification-modal__message">{m}</p>)}
            </div>
            <div className="modal-footer">
                <Button onClick={hideModal} red className="notification-modal__close-button">Ok <i className="fal fa-long-arrow-right"></i></Button>
            </div>
        </div>
  
    </Modal>
  );
  
};

export default TemplateNotificationModal;