import React from 'react';
import classNames from 'classnames';

const Button = ({
  onClick,
  children,
  black,
  red,
  slim,
  bold,
  light,
  block,
  blank,
  square,
  outlined,
  fullWidth,
  iconFirst,
  alignLeft,
  alignRight,
  spaceBetween,
  className,
  submit,
  type
}) => {

  const buttonClasses = classNames('button',{
    'button--black': black,
    'button--red': red,
    'button--slim': slim,
    'button--bold': bold,
    'button--light': light,
    'button--block': block,
    'button--blank': blank,
    'button--square': square,
    'button--outlined': outlined,
    'button--full-width': fullWidth,
    'button--space-between': spaceBetween,
    'button--icon-first': iconFirst,
    'button--align-left': alignLeft,
    'button--align-right': alignRight
  }, className);

  const onButtonClick = onClick ? (e) => {
    e.preventDefault();
    onClick();
  } : () => {};

  return (
    <button className={buttonClasses} onClick={onButtonClick} type={submit ? 'submit' : type}>
      {children}
    </button>
  );

};

export default Button;