import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';
import {commonPost} from '../../helpers/apiHelper';

import {debounce} from '../../helpers/debounce';

const HeaderSearch = (props) => {

  const [isOpen, setOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [postQuery, setQuery] = useState('');

  const searchPanelEl = useRef(null);

  function handleClickOutside(event) {
    if (searchPanelEl.current && !searchPanelEl.current.contains(event.target)) {
      setOpen(false);
      handleClean();
    }
  };

  useEffect(() => {
    window.showSearchPanel = (event) => {
      event.preventDefault();
      setOpen(true);
    };

    document.addEventListener('mousedown', handleClickOutside);         

    bindToggleButtons();    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchPanelEl]);

  const bindToggleButtons = () => {
    const searchToggleButtons = document.querySelectorAll('.js-toggle-search');

    if (searchToggleButtons.length) {
      searchToggleButtons.forEach(button => {
        button.onclick = window.showSearchPanel;
      });
    };
  };

  const handleClose = (event) => {
    setOpen(false);
    handleClean();
  };

  const handleSubmit = async (event, searchQuery) => {
    
    if (event && event.target) {event.preventDefault();}

    const query = searchQuery || postQuery;

    var result = await commonPost(`${props.searchUrl}/QuickSearch`, {q: query});

    if (result && result.Items) {
      setResults(result.Items);
      
    } else {      
      console.log('Results is empty or wrong');
    }

  };

  const handleChange = (event) => {    
    const query = event.target.value;

    if (query.length > 2) {
      handleSubmit(event, query);         
      setQuery(query);       
    } else {
      setResults([]);
      setQuery(query);
    }
  };

  const handleClean = (event) => {
    setQuery('');
    setResults([]);
  };

    const getResultsList = () => {

    return (
      <div className="search-panel__results search-panel__results--active">
        <ul className="search-panel__results-list">
          {results.map((item, index) => (
            <li key={index} className="search-panel__results-list-item">
              <a href={item.Link} dangerouslySetInnerHTML={{__html: item.Title}}></a>
            </li>
          ))}
        </ul>
      </div>
    );

  };

  return (
    <div ref={searchPanelEl} className={classNames('search-panel', {'search-panel--active': isOpen})}>
      <div className="search-panel__logo" dangerouslySetInnerHTML={{__html: props.innerHTML}}></div>
      <div className="search-panel__wrapper">      

        <a className="search-panel__btn-close" onClick={handleClose}><i className="far fa-times"></i></a>

        <div className="search-panel__form">

          <form id="globalSearchForm" action={props.searchUrl} type="get">
            <label htmlFor="globalSearchInput">Search for</label>

            <div className="search-panel__form-group">
              <input type="text" className="search-panel__form-input" name="q" placeholder="E.g., Sports Activity Packages" aria-label="Search all sports activity packages" onChange={handleChange} value={postQuery}  />

              {postQuery.length > 0 && <span className="search-panel__form-btn-clean" onClick={handleClean}><i className="fal fa-times-circle"></i></span>}
              <button className="search-panel__form-btn-submit" type="submit"><i className="far fa-search"></i></button>
            </div>
          </form>

          {results.length > 0 && getResultsList()}
        </div>

      </div>
    </div>
  );
};

export default HeaderSearch;