import ScrollService from '../services/scrollService';
import {getScrollPosition, getViewPortDimentions} from '../helpers/positionHelper';

const header = document.querySelector('.header');

const getFirePoint = () => {
  let firePoint = 350;
  
  if (getViewPortDimentions().vw > 768) {
    firePoint = 900;
  }

  return firePoint;
};


if (header != null && header.dataset.hideStickyHeader !== 'true') {
  ScrollService.addAction(() => {
    if (getScrollPosition() > getFirePoint() / 3) {
      header.classList.add('header-sticky');      
    } else {
      header.classList.remove('header-sticky');    
    }
    if (getScrollPosition() > getFirePoint() / 2) {
      header.classList.add('header-sticky--animation');
    } else {
      header.classList.remove('header-sticky--animation');
    }    
    if (getScrollPosition() > getFirePoint()) {
      header.classList.add('header-sticky--show');
    } else {
      header.classList.remove('header-sticky--show');
    }
  });
}
