import resizeService from '../services/resizeService';

import {commonPost} from '../helpers/apiHelper';
import React from 'react';
import ReactDOM from 'react-dom';
import BNPButtons from '../features/CompareAssistantPage/BNPButtons.jsx';
import {data} from 'jquery';

const SHOW_NAV_CLASS = 'main-navigation--show';
const SHOW_OVERLAY_CLASS = 'header__layout-overlay--show';
const SHOW_MEGAMENU_CLASS = 'mega-menu--show';

const header = document.querySelector('.header');

const showDesktopMenuButton = document.querySelector('#showDesktopTopMenuButton');
const showMobileMenuButton = document.querySelector('#showMobileTopMenuButton');

const showMobileMegaMenuButtons = document.querySelectorAll('.mega-menu_btn-show--mobile');
const showDesktopMegaMenuButtons = document.querySelectorAll('.mega-menu_btn-show--desktop');

const closeDesktopMenuButton = document.querySelector('#closeDesktopTopMenuButton');
const closeMobileMenuButton = document.querySelector('#closeMobileTopMenuButton');

const mobileMenu = document.querySelector('#mobileTopMenu');
const desktopMenu = document.querySelector('#desktopTopMenu');

const mobileMegaMenus = document.querySelectorAll('.mega-menu--mobile');
const desktopMegaMenus = document.querySelectorAll('.mega-menu--desktop');

const layoutOverlay = document.querySelector('.header__layout-overlay');

const megaMenuModals = document.querySelectorAll('.mega-menu__modal-block');

if (megaMenuModals.length) {
  megaMenuModals.forEach((menu) => {
    document.body.appendChild(menu);
  });
}

const bNPButtons = document.querySelectorAll('.mega-menu-item__bnp-link');
const bNPModal = document.querySelector('#megaMenuPageModal');

if (bNPButtons.length && bNPModal) {
  const props = {
    message: bNPModal.dataset.bnpMessage,
    startBuildLabel: bNPModal.dataset.bnpButtonLabel,
    bnpUrl: bNPModal.dataset.bnpUrl,
    bnpServiceUrl: bNPModal.dataset.bnpServiceUrl,
    checkCartUrl: bNPModal.dataset.checkCartUrl,
  };

  ReactDOM.render(<BNPButtons {...props} />, bNPModal);

  bNPButtons.forEach(button => {
    button.onclick = (event) => {
      event.preventDefault();
       window.dataLayer = window.dataLayer || [];
       dataLayer.push({
         'event': 'start_build_and_price',
         'pagePath': bNPModal.dataset.bnpUrl + button.dataset.series,
         'series': button.dataset.series,
         'model': button.dataset.model
       });

      if (window.showBnpModal) {
        window.showBnpModal(button.dataset.model, button.dataset.series);       
      }      
    };
  });
}

let hideOverlayCallback = (event) => {
  event.preventDefault();

  hideAllMenus();
};

const showOverlay = () => {
  layoutOverlay.classList.add(SHOW_OVERLAY_CLASS);
  document.body.classList.add('no-scroll');
  layoutOverlay.addEventListener('click', hideOverlayCallback);
};

const hideOverlay = () => {
  layoutOverlay.classList.remove(SHOW_OVERLAY_CLASS);
  document.body.classList.remove('no-scroll');
  layoutOverlay.removeEventListener('click', hideOverlayCallback);
};

const hideMenu = (menu) => {
  hideOverlay();
  menu.classList.remove(SHOW_NAV_CLASS);
};

const hideAllMenus = () => {
  hideOverlay();
  desktopMenu.classList.remove(SHOW_NAV_CLASS);
  mobileMenu.classList.remove(SHOW_NAV_CLASS);

  if (mobileMegaMenus.length) {
    mobileMegaMenus.forEach(menu => {
      menu.classList.remove(SHOW_MEGAMENU_CLASS);
    });
  }
  if (desktopMegaMenus.length) {
    desktopMegaMenus.forEach(menu => {
      menu.classList.remove(SHOW_MEGAMENU_CLASS);
    });
  }
};

const showMenu = (menu) => {
  showOverlay();
  menu.classList.add(SHOW_NAV_CLASS);
};

const showMegaMenu = (menu) => {
  menu.classList.add(SHOW_MEGAMENU_CLASS);
};

const hideAllMegaMenus = () => {
  if (desktopMegaMenus.length) {
    desktopMegaMenus.forEach(menu => {
      menu.classList.remove(SHOW_MEGAMENU_CLASS);
    });
  }
};

const hideMegaMenu = (menu) => {
  menu.classList.remove(SHOW_MEGAMENU_CLASS);
};



const init = () => {

  showDesktopMenuButton.onclick = (event) => {
    event.preventDefault();
    showMenu(desktopMenu);
  };

  showMobileMenuButton.onclick = (event) => {
    event.preventDefault();
    showMenu(mobileMenu);
  };

  closeDesktopMenuButton.onclick = (event) => {
    event.preventDefault();
    hideMenu(desktopMenu);
  };

  closeMobileMenuButton.onclick = (event) => {
    event.preventDefault();
    hideMenu(mobileMenu);
  };

  // Mobile Mega Menu
  if (showMobileMegaMenuButtons.length > 0) {
    showMobileMegaMenuButtons.forEach((nav) => {
      const mobileMegaMenu = document.querySelector(nav.dataset.target);
      const closeButton = mobileMegaMenu.querySelector('.mega-menu__back-button');

      if (mobileMegaMenu != null) {
        nav.onclick = (event) => {
          event.preventDefault();          
          showMegaMenu(mobileMegaMenu);
        };

        closeButton.onclick = (event) => {
          event.preventDefault();
          hideMegaMenu(mobileMegaMenu);
        };
      };      
    });
  };

  // Desktop Mega Menu
  if (showDesktopMegaMenuButtons.length > 0) {
    showDesktopMegaMenuButtons.forEach((nav) => {
      const desktopMegaMenu = document.querySelector(nav.dataset.target);

      if (desktopMegaMenu != null) {
        nav.onmouseenter = (event) => {
          if (!header.classList.contains('header-sticky--show')) {
            hideAllMegaMenus();
            layoutOverlay.classList.add(SHOW_OVERLAY_CLASS);
            layoutOverlay.addEventListener('click', hideOverlayCallback);
            showMegaMenu(desktopMegaMenu);
          }          
        };

        nav.onmouseleave = () => {
          setTimeout(() => {

            const hoverElements = document.querySelectorAll(':hover');

            let hoverOut = true;

            for(var entry of hoverElements.entries()) { 
              if (entry[1] == desktopMegaMenu || entry[1] == nav) {
                return hoverOut = false;
              }
            }

            if (hoverOut) {
              hideMegaMenu(desktopMegaMenu);
            }
            
          }, 300);

          if (nav.classList.contains('nav-active')) {
            layoutOverlay.classList.add(SHOW_OVERLAY_CLASS);
            layoutOverlay.addEventListener('click', hideOverlayCallback);
          }
          else {
            hideOverlay();
          }
        };

        desktopMegaMenu.onmouseenter = () => {
          nav.classList.add('nav-active');
          layoutOverlay.classList.add(SHOW_OVERLAY_CLASS);
          layoutOverlay.addEventListener('click', hideOverlayCallback);
        };

        desktopMegaMenu.onmouseleave = () => {
          nav.classList.remove('nav-active');
          hideOverlay();
          hideMegaMenu(desktopMegaMenu);
        };
      };
    });
  };

  resizeService.addAction(hideAllMenus);

};

if (
  showDesktopMenuButton &&
  showMobileMenuButton &&
  closeDesktopMenuButton &&
  closeMobileMenuButton &&
  mobileMenu &&
  desktopMenu &&
  layoutOverlay
) {
  init();
}
