import {commonPost} from '../../helpers/apiHelper';
import React from 'react';
import BNPButtons from './BNPButtons.jsx';
import ReactDOM from 'react-dom';

const removeProduct = async (url, model) => {
    const result = await commonPost(url,
      {
          code: model
      });

    if (result.success) {
        window.location.reload();
    };
};

const removeButtons = document.querySelectorAll('.compare-assistant__product-btn-remove');

if (removeButtons.length) {
    removeButtons.forEach(button => {
        button.onclick = (event) => {

            event.preventDefault();
      
            const url = button.href;
            const model = button.dataset.model;

            removeProduct(url, model);    
        };
    });
}

const bNPButtons = document.querySelectorAll('.compare-assistant__product-cta');
const bNPModal = document.querySelector('#compareAssistantPageModal');


if (bNPButtons.length && bNPModal) {

    const props = {
        message: bNPModal.dataset.bnpMessage,
        startBuildLabel: bNPModal.dataset.bnpButtonLabel,
        bnpUrl: bNPModal.dataset.bnpUrl,
        bnpServiceUrl: bNPModal.dataset.bnpServiceUrl,
        checkCartUrl: bNPModal.dataset.checkCartUrl,
    };

    ReactDOM.render(<BNPButtons {...props} />, bNPModal);

bNPButtons.forEach(button => {
    button.onclick = (event) => {

        event.preventDefault();

        if (window.showBnpModal) {
            window.showBnpModal(button.dataset.model, button.dataset.series);
        }
      
    };
});
}  

 const addModelContainer = document.getElementById('compareAssistantAddModel');

if (addModelContainer) {
    var data = JSON.parse(addModelContainer.dataset.initial),
       series = data.series,
       comparedskus = data.comparedskus || [],
       seriesSelect = document.getElementById('caSeriesSelect'),
       modelsSelect = document.getElementById('caModelSelect'),
       addModelBtn = document.getElementById('addModelBtn');
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    var resetAddModelModal = function(){
        $('.add-model-error').addClass('d-none');
        $('#compare-assistant__model-showcase').html('');
        $('#caModelSelect').prop('selectedIndex', 0);
        $('#caModelSelect').prop('disabled', true);
        $('#caSeriesSelect').prop('selectedIndex', 0);
    };

    modelsSelect.disabled = true;

    seriesSelect && $(seriesSelect).on('change blur', function() {
        let selectedSeries = this.value;
        console.log(selectedSeries);
        modelsSelect.innerHTML = '';
        series && series.forEach(function(item) {
            if (item.Code === selectedSeries) {
                let models = item.Models,
                    disabledOption = document.createElement('option');

                disabledOption.textContent = 'Select model';
                disabledOption.selected = true;
                disabledOption.disabled = true;
                disabledOption.value = 'none';
                modelsSelect.disabled = false;
                modelsSelect.appendChild(disabledOption);

                models && models.forEach(function(model) {
                    let modelOption = document.createElement('option');
                    modelOption.value = model.Code;
                    modelOption.innerHTML = model.Name;
                    if(comparedskus.indexOf(model.Code) !== -1) {
                        modelOption.disabled = true;
                    }
                    modelOption.setAttribute('data-currentmodel', JSON.stringify(model));
                    modelsSelect.appendChild(modelOption);
                });
            }
        });
    });

    modelsSelect && $(modelsSelect).on('change blur', function() {
        let selectedModel = JSON.parse(this.options[this.selectedIndex].getAttribute('data-currentmodel')),
            modelShowCase = document.getElementById('compare-assistant__model-showcase');
        modelShowCase.innerHTML = '';
        modelShowCase.innerHTML = '<img class="showcase-img" alt="' + selectedModel.Image.AltText + '" src="' + selectedModel.Image.ImageUrl + '" />' +
                                  '<div class="showcase-content"><h3>' + selectedModel.Name + '</h3><p>' + selectedModel.Teaser + '</p><h5>Starting At</h5><span>' + formatter.format(selectedModel.Price) + '</span></div>';
        addModelBtn.setAttribute('data-code', selectedModel.Code);
        console.log(selectedModel);
        $('.add-model-error').addClass('d-none');
    });

    addModelBtn.addEventListener('click', function() {
        if(modelsSelect.value === 'none') {
            $('.add-model-error').removeClass('d-none');
            return;
        }

        $('.add-model-error').addClass('d-none');

        $.ajax({
            url: '/compareassistantpage/AddToCompare',
            type: 'POST',
            dataType: 'json',
            data: JSON.stringify({code: this.dataset.code}),
            contentType: 'application/json; charset=utf-8',
            success: function(data) {
                $('#addModelModal').modal('hide');
                window.location.reload();
            },
            error: function(data) {
                console.log(data);
            }
        });

    });

    $('#addModelModal').on('hidden.bs.modal', function(e) {
        resetAddModelModal();
    });
}