import React from 'react';
import Modal from 'react-bootstrap/Modal';

import Button from './Button.jsx';

const BnpModal = ({
  hide,
  isOpen,
  message,
  startBuild,
  startBuildLabel,
}) => {

  return (
    <Modal 
      show={isOpen}
      onHide={hide}
      className="bnp-modal basic-modal"
      size="lg"
      centered>
        
        <div className="basic-modal__container">
  
          <div className="basic-modal__content bnp-modal__content">
            
            <button onClick={hide} className="basic-modal__close-button"><i className="fal fa-times"></i></button>
  
            <div dangerouslySetInnerHTML={{
              __html: message
            }}></div>

            <div className="bnp-modal__actions">
                      <Button red onClick={startBuild}>
                          {startBuildLabel}
                          <i className="fal fa-long-arrow-right"></i>
                      </Button>
              <button className="cta-link my-2" onClick={hide}>Cancel</button>
            </div>

          </div>
  
        </div>
  
    </Modal>
  );
};

export default BnpModal;