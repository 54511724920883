import 'lazysizes';

document.addEventListener('lazybeforeunveil', function(e){
  var bg = e.target.getAttribute('data-bg');
  if (bg) {
      e.target.style.backgroundImage = 'url(' + bg + ')';
  }
});

import './template/footerNavigation';
import './template/mainNavigation';
import './template/stickyNav';
import './services/orientationChangeService';


import './features/ScheduleTestDriveBlock/';

import './features/ctaSliderBlock';
import './features/basicHeroBlock';
import './features/heroSliderBlock';
//import './features/fullWidthSlideBlock';
//import './features/FeatureCalloutContainerBlock/featureCalloutContainerBlock';
//import './features/sCurveItemBlock';
import './features/productCarouselBlock';
import './features/productFeaturesBlock';
//import './features/ReviewsBlock';
import './features/CompareDrawer';
import './features/QuizModal';
import './features/VideoGallery';

import './ourStoryPage';
import './ownersHubPage';
import './features/CompareAssistantPage';
import './features/TemplateLocationDetectModal';
import './features/TemplateNotificationModal';
import './features/Search';
