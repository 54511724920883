import React, {useState, useEffect} from 'react';

import {serializeQuery} from '../../helpers/urlHelper';
import locationService from '../../services/locationService';

import LocationDetectModal from '../common/LocationDetectModal.jsx';

const LocationDetectModalWrapper = (props) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const headerButton = document.getElementById('findADealerButton');
    if (headerButton) {
      headerButton.onclick = handleLinkClick;
    }

    const pdpButton = document.getElementById('pdpTestDriveButton');
    if (pdpButton) {
      pdpButton.onclick = handleLinkClick;
    }

    const links = document.querySelectorAll('.footer-navigation__link, .top-navigation__link, .cta-block > a');
    
    links.forEach((link) => {
      if (link.pathname === props.actionUrl) {
        link.onclick = handleLinkClick;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLinkClick = (e) => {
    e.preventDefault();
    const storedZip = locationService.getSessionZip();
    storedZip ? gotoFindADealer(storedZip) : setOpen(() => true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const onZipSelect = (zip) => {
    hideModal();
    
    gotoFindADealer(zip);
  };

  const gotoFindADealer = (zip) => {
    locationService.setSessionZip(zip);
    const params = serializeQuery({zip});
    const url = `${props.actionUrl}?${params}`;
    window.location.href = url;
  };

  return <LocationDetectModal isOpen={isOpen} hide={hideModal} onZipSelect={onZipSelect}/>;
};

export default LocationDetectModalWrapper;