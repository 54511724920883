$(document).ready(()=>{

    const quizModal = $('#QuizModal');

    const headerQuiz = $('#headerQuizModalBlock, .js-mega-menu-quiz');


    if (headerQuiz.length > 0) {

        headerQuiz.each(function(){
            const iFrame = $(this).find('iframe');

            $(this).on('hidden.bs.modal', function(){
                iFrame.attr('src', iFrame.attr('src'));
            });
        });      

    }

    if (quizModal.length > 0) {

        const iFrame = quizModal.find('iframe');

        quizModal.on('hidden.bs.modal', function(){
            iFrame.attr('src', iFrame.attr('src'));
        });

        const quizUrl = quizModal.data('targetUrl');

        const quizLinks = $('a').filter(function(){           
            return $(this).attr('href') === quizUrl;
        });

        quizLinks.click((event) => {
            event.preventDefault();
            quizModal.modal('show');
        });
    }
});
