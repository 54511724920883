//import FadeInView from './features/fadeInView';

//const ourStoryPage = document.querySelector('.OurStoryPage');

//if (ourStoryPage !== null) {
//  const fading = document.querySelectorAll('.fading');

//  if (fading.length) {

//    // Hero Block not fades on start
//    const headerVideoFading = new FadeInView(fading[0], true);

//    for (let i = 1; i < fading.length; i++) {
//      const fadingBlock = new FadeInView(fading[i], false);
//    }

//  }
//}

// Removing feature as it being handle globally by fadehelper.js