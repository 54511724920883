import Swiper from 'swiper/bundle';
import 'swiper/swiper.scss';

const SLIDES_BLOCK_SELECTOR = '.product-carousel__slider';
const SLIDES_LIST_SELECTOR = '.product-carousel__slider-slides';
const SLIDE_SELECTOR = '.product-carousel-item ';
const INDICATOR_SELECTOR_PARENT = '.swiper-pagination';
const INDICATOR_SELECTOR = '.product-carousel__slider-indicators-link';
const DESKTOP_PREV_BUTTON_SELECTOR = '.product-carousel__slider-control-prev';
const DESKTOP_NEXT_BUTTON_SELECTOR = '.product-carousel__slider-control-next';
const COLOR_SWATCHERS_SELECTOR = '.product-carousel__product-colors-swatch';
const PRODUCT_IMAGE_SELECTOR = '.product-carousel__product-img';

const ACTIVE_IMAGE_CLASS = 'product-carousel__product-img--active';

class ProductCarouselBlock {
  constructor(container) {
    if (!container) {
      return;
    }

    this.slider = container.querySelector(SLIDES_BLOCK_SELECTOR);
    this.slidesContainer = container.querySelector(SLIDES_LIST_SELECTOR);
    this.slides = container.querySelectorAll(SLIDE_SELECTOR);
    this.indicatorsParent = container.querySelector(INDICATOR_SELECTOR_PARENT);
    this.indicators = container.querySelectorAll(INDICATOR_SELECTOR);
    this.desktopPrevButton = container.querySelector(DESKTOP_PREV_BUTTON_SELECTOR);
    this.desktopNextButton = container.querySelector(DESKTOP_NEXT_BUTTON_SELECTOR);

    this.animationRunning = false;

    this.slideGap = null;
    const slideLength = this.slides.length;

    this.desktopPrevButton.style.opacity = ( slideLength < 2) ? '0': '1';
    this.desktopNextButton.style.opacity = ( slideLength < 2) ? '0' : '1';
    this.indicatorsParent.style.opacity = ( slideLength < 2) ? '0' : '1';

    const swiper = new Swiper(this.slider, {
      slidesPerView: 'auto',
      spaceBetween: 0,
      centeredSlides: true,
      loopAdditionalSlides: (slideLength === 1 ? 0 : 1),
      loop: (slideLength === 1 ? false : true),
      pagination: {
        el: this.indicatorsParent,
        clickable: true,
        renderBullet: function(index, className) {
          return '<span class="dot swiper-pagination-bullet"></span>';
        }
      }, 

      navigation: {
        nextEl:  this.desktopNextButton,
        prevEl: this.desktopPrevButton,
      },      

      breakpoints: {
        320: {
          slidesPerView: 1,
          allowTouchMove: true
        },
        768: {
          slidesPerView: 'auto',
          loopedSlides: 1
        },
        1024: {
          pagination: false,
          slidesPerView: 'auto',
          centeredSlides: true,
          allowTouchMove: false
        },
      }
    });

    if (this.slides.length) {
      this.slides.forEach(slide => {
        const productCode = slide.dataset.productCode;
        const swatches = slide.querySelectorAll(COLOR_SWATCHERS_SELECTOR);
        const images = slide.querySelectorAll(PRODUCT_IMAGE_SELECTOR);

        if (swatches.length && images.length) {
         swatches.forEach((swatch, index) => {
            swatch.onclick = (e) => {

              const newSlides = container.querySelectorAll(SLIDE_SELECTOR);
              //prevent slide images blinking because of cloned slides
              newSlides.forEach(slide => {
                if (slide.dataset.productCode == productCode) {
                  const affectedImages = slide.querySelectorAll(PRODUCT_IMAGE_SELECTOR);

                  affectedImages.forEach(image => {
                    image.classList.remove(ACTIVE_IMAGE_CLASS);
                  });
    
                  affectedImages[index].classList.add(ACTIVE_IMAGE_CLASS);
                }
              });
              
            };
          });
        }
      });
    }
  }

}


const sliders = document.querySelectorAll('.product-carousel-block');

if (sliders.length) {

  sliders.forEach(slider => {
    const carousel = new ProductCarouselBlock(slider);
  });

}