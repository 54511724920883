import React from 'react';
import ReactDOM from 'react-dom';

import TemplateNotificationModal from './TemplateNotificationModal.jsx';

const container = document.querySelector('#templateTemplateNotificationModal');

if (container) {
  
  try {    
    ReactDOM.render(<TemplateNotificationModal/>, container);

  } catch {
    console.warn('TemplateNotificationModal: failed to initialize component');
  }

}