

import {getElementPosition} from '../helpers/positionHelper';
import {applyFadeAnimation} from '../helpers/fadeHelper';

const basicHeroBlocks = document.querySelectorAll('.basic-hero-block');

if (basicHeroBlocks.length) {

  basicHeroBlocks.forEach(block => {
    const scrollBtn = block.querySelector('.basic-hero-block__scroll-down-button');   
    const contentItems = block.querySelectorAll('.basic-hero-block > *');    
    const headerAnimationType = block.dataset.headerAnimationType;
    
    if (headerAnimationType && contentItems.length) {
      contentItems.forEach(item => {
        applyFadeAnimation(headerAnimationType, item); 
      });
    }    

    const scrollToNextBlock = (e) => {
      if (e) {
        e.preventDefault();
      }
    
      const offset = getElementPosition(block);
    
      $([document.documentElement, document.body]).animate({
        scrollTop: offset.bottom
      }, 1000);
    };
    
    if (scrollBtn) {
      scrollBtn.onclick = scrollToNextBlock;
    }

  });
}