import React from 'react';
import classNames from 'classnames';

const TextInput = (props) => {

  const {
    placeholder,
    register,
    errorsLabels,
    error,
    name,
    type,
    fullWidth,
    fontBold,
    iconClass,
    onKeyUp = () => {},
    onBlur = () => {},
  } = props;
  
  const classes = classNames('input', {
    'input--full-width': fullWidth,
    'input--bold': fontBold,
    'icon-input': !!iconClass,
  });

  return (
    <div className={classes}> 
      {!!iconClass && <i className={iconClass}></i>}   
      <input 
        name={name} 
        ref={register ? register : null} 
        type={type ? type : 'text'}
        // onInput = { (e) => {
        //     type ? e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) : '';
        // }}
        placeholder={placeholder} 
        onKeyUp={onKeyUp}
        onBlur={onBlur}
        />
      {error && errorsLabels[error.type] && <p className="form-error">{errorsLabels[error.type]}</p>}
    </div>
  );
};

export default TextInput;