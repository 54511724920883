import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';

const CompareDrawer = React.lazy(() => import(
  /* webpackChunkName: "CompareDrawer.chunk" */
  /* webpackMode: "lazy-once" */
  './CompareDrawer.jsx'
));

const container = document.getElementById('compareDrawer');

if (container) {
  
  try {    
    const props = JSON.parse(container.dataset.initialData);
    
    ReactDOM.render(
      <Suspense fallback={<div></div>}>
        <CompareDrawer {...props}/>
      </Suspense>, container);

  } catch {
    console.warn('CompareDrawer: failed to initialize component');
  }

}