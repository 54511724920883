import React from 'react';
import Modal from 'react-bootstrap/Modal';
import classNames from 'classnames';
import {useForm} from 'react-hook-form';

import Button from './Button.jsx';
import TextInput from './TextInput.jsx';
import GetLocationButton from './GetLocationButton.jsx';
import {
  clearZip,
  zipRules,
  zipErrorsLabels,
} from '../../helpers/inputHelper';

const LocationDetectModal = ({
  hide,
  isOpen,
  onZipSelect
}) => {

  const modalClasses = classNames('location-detect-modal', 'simple-modal');
  const {register, handleSubmit, watch, errors, setValue} = useForm();

  const onSubmit = data => {
    onZipSelect(data.zip);
  };

  const updateQuery = (value) => {
    setValue('zip', value);
  };

  return (
    <Modal 
      show={isOpen}
      onHide={hide}
      className={modalClasses}
      size="xl"
      centered>
        
        <div className="location-detect-modal__container">
  
          <div className="location-detect-modal__content">
            
            <button onClick={hide} className="location-detect-modal__close-button"><i className="fal fa-times"></i></button>
  
            <h4 className="location-detect-modal__header">Enter your location</h4>
            <form 
              className="location-detect-modal__form" 
              onSubmit={handleSubmit(onSubmit)}>         
              <h5 className="location-detect-modal__label">ZIP Code</h5>       
              <div className="zip-search-form location-detect-modal__field">               
                <TextInput
                  name="zip"
                  onKeyUp={clearZip}
                  onBlur={clearZip}
                  register={register(zipRules)}
                  error={errors.zip}
                  errorsLabels={zipErrorsLabels}          
                  placeholder="Please enter ZIP code"/>
                <GetLocationButton callback={updateQuery}/>
              </div>
              <div className="location-detect-modal__actions">
                <Button submit className="location-detect-modal__zip-search-form-submit">confirm <i className="fal fa-long-arrow-right"></i></Button>              
              </div>
            </form>

          </div>
  
        </div>
  
    </Modal>
  );
};

export default LocationDetectModal;