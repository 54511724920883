$(document).ready(function() {
    const videoGalleryPage = $('.video-gallery-page');
    const videoModal = $('#VideoModal');
    const videoContent = $('.jsVideoGalleryContent').find('.resource-block');

    if (videoGalleryPage !== null) {
        if (videoContent.length > 0) {
            videoContent.each(function() {
                const videoLinks = $(this).find('.image-text-cta__image a');

                videoLinks.click(function(e) {
                    e.preventDefault();

                    $(e.currentTarget).parents('.image-text-cta__image').siblings('.image-text-cta__cta').find('.cta-block-vimeo-modal').modal('show');
                });
            });
        }
    }
});
