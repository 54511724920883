import React from 'react';

import Button from './Button.jsx';
import locationService from '../../services/locationService';

const GetLocationButton = ({
  callback,
}) => {
  

  //callback

  const onButtonClick = async () => {

    const zip = await locationService.detectLocationZip();
    
    if (zip) {
      callback(zip);
    } 

  };
  
  return (
    <Button 
      red 
      square 
      className="get-location-button"
      onClick={onButtonClick}>
      <i className="far fa-location-arrow"></i>
    </Button>
  );
};

export default GetLocationButton;