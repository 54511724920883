import React, {useState, useEffect} from 'react';
import BnpModal from '../common/BnpModal.jsx';
import {commonPost} from '../../helpers/apiHelper';

const BNPButtons = (props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const checkCart = async () => {
    const result = await commonPost(props.checkCartUrl, {});
    return result.Success;
  };

  useEffect(() => {
    window.showBnpModal = async (model, series) => {
      if (model != null && series !== null) {       

        setSelectedProduct((product) => {
          return {
            model: model,
            series: series
          };
        });

        const ifCartAlreadyExist = await checkCart();

        if (ifCartAlreadyExist) {
          setModalOpen(true);
        } else {
          bnpStartBuild(model, series);
        }        

      };
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bnpStartBuild = async (model, series) => {

    let product = {
      Series: '',
      Model: ''
    };

    if (!selectedProduct && model && series) {

      product.Series = series;
      product.Model = model; 

    } else if (selectedProduct.model != null && selectedProduct.series != null) {

      product.Series = selectedProduct.series;
      product.Model = selectedProduct.model;      

    } 

    window.dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event': 'start_build_and_price',
        'pagePath': props.bnpUrl + product.Series,
        'series': product.Series,
        'model': product.Model
    });
    
    const result = await commonPost(props.bnpServiceUrl, product);

    if (result) {      
     window.location.href = props.bnpUrl;
    } 
  };

  const hideModal = () => {
    setSelectedProduct(null);
    setModalOpen(false);
  };
  
  return (<BnpModal message={props.message} startBuildLabel={props.startBuildLabel} isOpen={isModalOpen} hide={hideModal}  startBuild={bnpStartBuild} />);
};

export default BNPButtons;