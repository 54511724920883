import React from 'react';
import ReactDOM from 'react-dom';

import LocationDetectModalWrapper from './LocationDetectModalWrapper.jsx';

const container = document.querySelector('#templateLocationDetectModal');

if (container) {
  
  try {    
    const props = JSON.parse(container.dataset.initialData);
    
    ReactDOM.render(<LocationDetectModalWrapper {...props}/>, container);

  } catch {
    console.warn('NewsListing: failed to initialize component');
  }

}