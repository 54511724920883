import {getElementPosition, getScrollPosition} from '../helpers/positionHelper';
import resizeService from '../services/resizeService';

const FEATURE_IMAGE_SELECTOR = '.pdp-features__feature-image';
const FEATURE_IMAGE_WRAPPER = '.pdp-features__feature-image-wrapper';
const FEATURE_TEXT_SELECTOR = '.pdp-features__feature-text-wrapper';
const FEATURE_FIXED_CLASS = 'pdp-features__feature-image--fixed';
const FEATURE_ABS_CLASS = 'pdp-features__feature-image--abs';

class ProductFeaturedBlock {
  constructor(container){
    this.container = container;
    this.image = container.querySelector(FEATURE_IMAGE_SELECTOR);
    this.imageWrapper = container.querySelector(FEATURE_IMAGE_WRAPPER);
    this.textWrapper = container.querySelector(FEATURE_TEXT_SELECTOR);
    this.position = getElementPosition(this.container);    
    
    if (this.image != null && window.innerWidth < 768) {         
      window.addEventListener('scroll', this.handleScroll);
    }

    resizeService.addAction(this.recalcWrapperHeight);
    this.recalcWrapperHeight();
  }

  recalcWrapperHeight = () => {
    const height = this.getImgHeight();
    this.imageWrapper.style.height = height;
    this.textWrapper.style.height = height;
  }

  getImgHeight = () => {
    const img = this.image.querySelector('img');
    return img.scrollHeight ? `${img.scrollHeight}px` : '100vh';
  }

  handleScroll = (e) => {

    if (getScrollPosition() >= getElementPosition(this.container).top && getScrollPosition() <= getElementPosition(this.container).bottom) {
      this.image.classList.add(FEATURE_FIXED_CLASS);
    } else {
      this.image.classList.remove(FEATURE_FIXED_CLASS);
    }

    if (getElementPosition(this.textWrapper).top < getScrollPosition()) {
      this.image.classList.add(FEATURE_ABS_CLASS);
      this.image.style.top = this.getImgHeight();
    } else {
      this.image.classList.remove(FEATURE_ABS_CLASS);
      this.image.style.top = 0;
    }
    
  }
}

const productFeaturesBlocks = document.querySelectorAll('.pdp-features__feature');

if (productFeaturesBlocks.length) {
  productFeaturesBlocks.forEach(item => {
    const mobileFeature = new ProductFeaturedBlock(item);
  });
  
}
