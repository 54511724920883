import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';

const ScheduleTestDriveBlockForm = React.lazy(() => import(
  /* webpackChunkName: "ScheduleTestDriveBlockForm.chunk" */
  /* webpackMode: "lazy-once" */
  './ScheduleTestDriveBlockForm.jsx'
));

const containers = document.querySelectorAll('.schedule-test-drive__form-wrapper');

containers.forEach(container => {
  try {    
    const props = JSON.parse(container.dataset.initialData);
    
    ReactDOM.render(<Suspense fallback={<div></div>}>
      <ScheduleTestDriveBlockForm {...props}/>
    </Suspense>, container);

  } catch {
    console.warn('ScheduleTestDriveBlockForm: failed to initialize component');
  }
});
