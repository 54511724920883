import swipesHelper from '../helpers/swipesHelper';
import resizeService from '../services/resizeService';

const ACTIVE_SLIDE_CLASS = 'cta-slide--active';
const IS_ANIMATING_CLASS = 'cta-slider--animating';
const MOBILE_PREV_BUTTON_SELECTOR = '.cta-slider__step-button--prev';
const MOBILE_NEXT_BUTTON_SELECTOR = '.cta-slider__step-button--next';
const DESKTOP_PREV_BUTTON_SELECTOR = '.cta-slider__desktop-step-button--prev';
const DESKTOP_NEXT_BUTTON_SELECTOR = '.cta-slider__desktop-step-button--next';
const MOBILE_HIDDEN_BUTTON_CLASS = 'cta-slider__step-button--hidden';
const CURSOR_SELECTOR = '.cta-slider__float-cursor';
const CURSOR_PREV_CLASS = 'cta-slider__float-cursor--prev';
const CURSOR_NEXT_CLASS = 'cta-slider__float-cursor--next';
const CURSOR_HIDDEN_CLASS = 'cta-slider__float-cursor--hidden';

class CtaSliderBlock {

  constructor(container) {

    if (!container) {
      return;
    }

    this.slider = container;
    this.slidesContainer = container.querySelector('.cta-slider__slides');
    this.slides = container.querySelectorAll('.cta-slide');
    this.currentSliderNumber = container.querySelector('.steps-counter__current-step-number');
    this.slidesNumber = container.querySelector('.steps-counter__steps-length');
    this.mobileNextButton = container.querySelector(MOBILE_NEXT_BUTTON_SELECTOR);
    this.mobilePrevButton = container.querySelector(MOBILE_PREV_BUTTON_SELECTOR);
    this.desktopNextButton = container.querySelector(DESKTOP_NEXT_BUTTON_SELECTOR);
    this.desktopPrevButton = container.querySelector(DESKTOP_PREV_BUTTON_SELECTOR);
    
    if (!this.slides.length) {
      console.warn('ctaSliderBlock: No slides error');
      return;
    }    

    this.mobilePrevButton.onclick = this.prevSlide;
    this.mobileNextButton.onclick = this.nextSlide;

    this.state = {
      current: 0,
    };

    this.updateContainerWidth();
    this.slidesNumber.innerHTML = this.slides.length;
    this.updateCounter();

    swipesHelper.detectSwipe(this.slidesContainer, this.nextSlide, this.prevSlide);
    this.initResizeEvent();


    //desktop buttons

    this.cursor = document.querySelector(CURSOR_SELECTOR);    

    $(this.slider).mousemove((e) => {
      //e.stopImmediatePropagation();
      const cursor = $(this.cursor);
      const target = $(event.target);
      const icon = target.find('i');
      const position = this.detectPosition(e);
      
      // update position of cursor
      cursor.css('left', e.clientX).css('top', e.clientY);

      const isLinkTag = e.target instanceof HTMLAnchorElement || e.target.nodeName == 'I';// target.is('a');
      const isHovered = cursor.hasClass(CURSOR_HIDDEN_CLASS);
      
      if (position == 'left' && (cursor.hasClass(CURSOR_NEXT_CLASS) || !cursor.hasClass(CURSOR_PREV_CLASS))) {
        cursor.removeClass(CURSOR_NEXT_CLASS).addClass(CURSOR_PREV_CLASS);
      } else if (position == 'right' && (cursor.hasClass(CURSOR_PREV_CLASS) || !cursor.hasClass(CURSOR_NEXT_CLASS))) {
        cursor.removeClass(CURSOR_PREV_CLASS).addClass(CURSOR_NEXT_CLASS);
      }
   
      // toggle the cursor class if necessary 
      if(isLinkTag && !isHovered) {
        cursor.addClass(CURSOR_HIDDEN_CLASS);
      } else if(!isLinkTag && isHovered) {
        cursor.removeClass(CURSOR_HIDDEN_CLASS);
      }
    });

    $(this.slider).on('click', (e) => {
      const target = $(e.target);
      const isLinkTag = target.is('a') || target.is('i');
      const vw = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

      if (vw < 992) {
        return;
      } 
      
      if(isLinkTag) {
        return;
      }

      const position = this.detectPosition(e);

      position == 'left' ? this.prevSlide() : this.nextSlide();

    });
    
    $(this.slider).mouseleave((e) => {
    
      const cursor = $(this.cursor);
      cursor.hide();
    
    });
    
    $(this.slider).mouseenter((e) => {
    
      const cursor = $(this.cursor);
      cursor.show();
    
    });

  }

  initResizeEvent = () => {
    resizeService.addAction(() => {
      /**
       * Author: Daniel Nedelcu
       * Date: 08/02/2020
       * Updated the nextSlideIndex to align to the current index on resize and not go back to the first slide in the carousel
       */
      const nextSlideIndex = (this.state.current) % this.slides.length;
      this.switchSlideTo(nextSlideIndex);
    });
  }

  updateContainerWidth = () => {
    this.slidesContainer.style.transform = 'translate(0, 0)';
    this.slidesContainer.style.width = `calc(100% * ${this.slides.length})`;
  }
  
  switchSlideTo = (index) => {

    this.slider.classList.add(IS_ANIMATING_CLASS);

    //render

    this.slides.forEach(slide => {
      slide.classList.remove(ACTIVE_SLIDE_CLASS);
    });

    const currentSlide = this.slides[index];

    currentSlide.classList.add(ACTIVE_SLIDE_CLASS);

    const innerWidth = this.slidesContainer.clientWidth;
    const xPos = index * currentSlide.clientWidth * -1;
    this.slidesContainer.style.transform = `translate(${xPos}px, 0)`;

    //update state
    this.state.current = index;
    this.updateCounter();
    this.updateButtons();

    setTimeout(() => {
      this.slider.classList.remove(IS_ANIMATING_CLASS);
    }, 700);
        
  }
  
  nextSlide = (e) => {
    if (e) {
      e.preventDefault();
    }

    const nextSlideIndex = (this.state.current + 1) % this.slides.length;
    this.switchSlideTo(nextSlideIndex);
  }
  
  prevSlide = (e) => {
    if (e) {
      e.preventDefault();
    }

    const nextSlideIndex = this.state.current -1;
    this.switchSlideTo(nextSlideIndex == -1 ? this.slides.length - 1 : nextSlideIndex );
  }

  updateCounter = () => {
    this.currentSliderNumber.innerHTML = ('0' + (this.state.current + 1)).slice(-2);
  }

  updateButtons = () => {

    if (this.state.current === 0) {
      this.mobileNextButton.classList.remove(MOBILE_HIDDEN_BUTTON_CLASS);
      this.mobilePrevButton.classList.add(MOBILE_HIDDEN_BUTTON_CLASS);
    } else if (this.state.current === this.slides.length - 1) {
      this.mobileNextButton.classList.add(MOBILE_HIDDEN_BUTTON_CLASS);
      this.mobilePrevButton.classList.remove(MOBILE_HIDDEN_BUTTON_CLASS);
    } else {
      this.mobileNextButton.classList.remove(MOBILE_HIDDEN_BUTTON_CLASS);
      this.mobilePrevButton.classList.remove(MOBILE_HIDDEN_BUTTON_CLASS);
    }

  }

  detectPosition = (e) => {
    const x = e.pageX - $(this.slider).offset().left;
    const half = this.slider.offsetWidth / 2;
    return(x > half ? 'right' : 'left');
  }

}

const ctaSlider = document.querySelectorAll('.cta-slider');
window.ctaSliders = [];
ctaSlider.forEach(cta => {
  window.ctaSliders.push(new CtaSliderBlock(cta));
});

