import {gsap} from 'gsap';
import swipesHelper from '../helpers/swipesHelper';

class HeroSliderBlock {

  constructor(container) {

    if (!container) {
      return;
    }

    this.container = container;

    this.slider = container.querySelector('.hero-slider-block__slider');
    this.slides = container.querySelectorAll('.hero-slider-block__slide');
    this.bullets = container.querySelectorAll('.hero-slider-block__bullet');
    this.prevButton = container.querySelector('.hero-slider-block__prev-button');
    this.nextButton = container.querySelector('.hero-slider-block__next-button');
    this.scrollToNextBlockButton = container.querySelector('.hero-slider-block__scroll-down-button');
    
    this.prevButton.onclick = this.prevSlide;
    this.nextButton.onclick = this.nextSlide;
    
    this.state = {
      current: 0,
      isAnimating: false,
      autoRotate: container.dataset.autoRotate == 'True' ? true : false,
      autoRotateSpeed: container.dataset.autoRotateSpeed || 10,
      hasBullets: this.bullets.length,
    };
    
    if (this.scrollToNextBlockButton) {
      this.scrollToNextBlockButton.onclick = this.scrollToNextBlock;
    }

    if (this.state.hasBullets) {
      this.bullets.forEach((bullet, index) => {
        bullet.onclick = (e) => {
          this.switchSlideTo(index);
        };
      });
    }

    if (this.state.autoRotate && this.state.autoRotateSpeed != 0) {
      this.state.autoRotateInterval = setInterval(() => {this.nextSlide();}, this.state.autoRotateSpeed * 1000);
    }

    swipesHelper.detectSwipe(this.slider, this.nextSlide, this.prevSlide);
  }

  
  switchSlideTo = (index) => {

    const xPos = index > this.state.current ? 100 : -100;

    this.state.prev = this.state.current || 0;
    this.state.current = index;

    if (this.state.hasBullets) {
      this.bullets[this.state.prev].classList.remove('hero-slider-block__bullet--active');
      this.bullets[this.state.current].classList.add('hero-slider-block__bullet--active');
    }

    const prevSlide = this.slides[this.state.prev];
    const prevVideo = prevSlide.querySelector('video');
    
    const nextSlide = this.slides[this.state.current];
    const nextVideo = nextSlide.querySelector('video');

    this.tl = gsap.timeline({
      paused: !0,
      onStart: function() {
        if (nextVideo) {
          nextVideo.currenTime = 0;
          nextVideo.play();
        }
      },
      onComplete: function() {
        if (prevVideo) {
          prevVideo.pause();
          prevVideo.currentTime = 0;
        }
      }
    });

    this.tl.set(nextSlide, {
      autoAlpha: 1,
      zIndex: 2
    })
    .fromTo([nextSlide, nextVideo], {x: `${xPos}%`}, {x: 0})        
    .set(prevSlide, {
      autoAlpha: 0
    })
    .set(nextSlide, {
      clearProps: 'zIndex'
    })
    .set(this.state, {
      isAnimating: !1
    })
    .call(function() {
      prevSlide.classList.remove('hero-slider-block__slide--active');
      nextSlide.classList.add('hero-slider-block__slide--active');
    });

    this.tl.play();
  }
  
  nextSlide = (e) => {
    if (e) {
      e.preventDefault();
    }

    const nextSlideIndex = (this.state.current + 1) % this.slides.length;
    this.switchSlideTo(nextSlideIndex);
  }
  
  prevSlide = (e) => {
    if (e) {
      e.preventDefault();
    }

    const nextSlideIndex = this.state.current -1;
    this.switchSlideTo(nextSlideIndex == -1 ? this.slides.length - 1 : nextSlideIndex );
  }

  scrollToNextBlock = (e) => {
    if (e) {
      e.preventDefault();
    }

    const offset = this.container.offsetHeight;

    $([document.documentElement, document.body]).animate({
      scrollTop: offset
    }, 1000);
  }

}

const hero = document.querySelector('.hero-slider-block');

if (hero) {
  const heroSlider = new HeroSliderBlock(hero);
}

